.page-title{
    font-weight: 700;
    font-size: 42px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #041a2d;
    @media (max-width: 899px) {
        font-size: 24px;
line-height: 120%;
    }
}

.contacts{
    margin-top: 68px;
    padding-bottom: 130px;
    @media (max-width: 899px) {
        margin-top: 42px;
        padding-bottom: 90px;
    }
    &-content{
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        @media (max-width: 1050px) {
            margin-top: 32px;
        }
    }
    &-info{
    
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #6d7888;
        max-width: 565px;
        p:not(:first-child){
            margin-top: 10px;
        }
        .contacts-text-lg:not(:first-child){
            margin-top: 5px;
        }
        @media (max-width: 1050px) {
            max-width: 100%;
            font-size: 14px;
            line-height: 140%;
        }
    }
    &-text-lg{
        font-weight: 600;
        font-size: 18px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #0d3854;
        @media (max-width: 1050px) {
            font-size: 16px;
            line-height: 130%;
        }
    }
    &-addr{
        margin-top: 22px;
    }
    &-req{
        display: flex;
        margin-top: 30px;
        flex-wrap: wrap;
        gap: 27px 78px;
        max-width: 532px;
        a{
            transition: all .5s;
            &:hover{
                color: #e3442e;
            }
        }
        @media (max-width: 1050px) {
            gap: 34px 78px;
            line-height: 140%;
            font-size: 16px;
            line-height: 140%;
.contacts-text-lg:not(:first-child){
    font-size: 18px;
    line-height: 130%;
}
        }     
        &__social{
            margin-top: 5px;
            display: flex;
            gap: 12px;
        }
        &__block{
            &-title{
                font-family: var(--font-family);
                font-weight: 700;
                font-size: 16px;
                line-height: 140%;
                text-transform: uppercase;
                color: #6d7888;
            }
            &-inf{
                margin-top: 14px;
                display: flex;
                flex-direction: column;
                &.-m100{
                    @media (max-width: 599px) {
                        width: 100%;
                    }
                }
                @media (max-width: 599px) {
                    &.--email{
                        min-width: 108px;
                    }
                }
            }
            &-inf-list{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 10px;
            }
            &.fg{
                flex-grow: 1;
            }
        }
    }
}

.contacts-map{
    height: 100%;
    width: 100%;
    iframe{
        height: 100%;
        width: 100%;
    }
    &-wrap{
        flex-grow: 1;
        margin-left: 40px;
        @media (max-width: 1050px) {
            display: none;
        }
    }
}

.contacts-map-m{
    @media (max-width: 1050px) {
        margin-top: 20px;
        height: auto;
        aspect-ratio: 1 / 1;
    }
}

.catalog{
    padding-bottom: 300px;
    @media (max-width: 720px) {
        padding-bottom: 77px;
    }
    &-head{
        position: relative;
        z-index: 3;
        display: flex;
        justify-content: space-between;
        @media (max-width: 1150px) {
            flex-direction: column;
        }

    }
    &-filters{
        display: flex;
        gap: 8px;
        @media (max-width: 1150px) {
            margin-top: 12px;
        }
        @media (max-width: 880px) {
            flex-direction: row-reverse;
            width: 100%;
        }
    }
    &-s{
        &-input{
            border-radius: 10px;
            padding: 12px 36px 12px 16px;
            width: 381px;
            height: 52px;
 
            font-weight: 400;
            font-size: 20px;
            line-height: 120%;
            letter-spacing: 0.02em;
            color: #6d7888;
            display: flex;
            background-color: #f4faff;
            &::placeholder{
                color: #6d7888;
            }
            @media (max-width: 880px) {
                border-radius: 5px;
                padding: 4px 4px 4px 12px;
                flex-grow: 1;
                height: 32px;
                font-size: 14px;
                width: 100%;
            }
        }
        position: relative;
        @media (max-width: 880px) {
            flex-grow: 1;
            display: flex;
            width: 100px;
        }
        &-btn{
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28' fill='none'%3E%3Cpath d='M3.5 24.5L10.4999 17.5M8.16667 11.6667C8.16667 16.177 11.823 19.8333 16.3333 19.8333C20.8437 19.8333 24.5 16.177 24.5 11.6667C24.5 7.15634 20.8437 3.5 16.3333 3.5C11.823 3.5 8.16667 7.15634 8.16667 11.6667Z' stroke='%236D7888' stroke-width='2.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            height: 28px;
            width: 28px;
            background-size: contain;
            position: absolute;
            right: 8px;
            top: 12px;
            @media (max-width: 880px) {
                height: 24px;
                width: 24px;
                top: 4px;
            }
        }
    }
    &-sort{
        &-head{
            border-radius: 10px;
            padding: 12px 8px 12px 16px;
            width: 381px;
            height: 52px;
 
            font-weight: 400;
            font-size: 20px;
            line-height: 120%;
            letter-spacing: 0.02em;
            color: #6d7888;
            display: flex;
            background-color: #f4faff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            &::after{
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 10.5L14 17.5L7 10.5' stroke='%236D7888' stroke-width='2.33333' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
            height: 28px;
            width: 28px;
            background-size: contain;
            background-repeat: no-repeat;
            flex-shrink: 0;
            @media (max-width: 880px) {
                height: 24px;
                width: 24px;
                
            }
            
            }
            @media (max-width: 880px) {
                border-radius: 5px;
                padding: 4px 4px 4px 12px;
   
                width: 113px;
                height: 32px;
                font-size: 14px;
                p{
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
        }
        position: relative;
        &-vars{
            position: absolute;
            display: none;
            width: 100%;
            box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        &-item{
        display: block;
            padding: 12px 8px 12px 16px;
            background-color: #fff;
            border-bottom: 1px solid #f4faff;
            cursor: pointer;
            &:hover{
                color: #e3442e;
            }
        }

    }

    }
    &-banner{
        margin-top: 69px;
        margin-bottom: 98px;
        
        display: flex;
        background-color: #083058;
        position: relative;
        align-items: center;
        justify-content: center;
        padding-top: 36px;
        padding-bottom: 32px;
             @media (max-width: 780px) {
        
        margin-bottom: 41px;
            }
        

        &::after{
            height: 169px;
            background: linear-gradient(180deg, rgba(133, 154, 176, 0) 0%, rgba(133, 154, 176, .5) 50%, #083058 100%);
            content: '';
            width: 100%;
            position: absolute;
            bottom: 0;
            background-size: contain;
            left: 0;
        }
        svg{
            position: absolute;
            width: 1535px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            filter: blur(100px);
            mix-blend-mode:overlay
        }
        img{
            position: relative;
            z-index: 2;
        }
        .logo{
            width: 260rem * 0.0625;
            margin-right: 20rem * 0.0625;
            margin-left: 20rem * 0.0625;
            
                @media (max-width: 780px) {
                width: 154px;}
        }
        .img2{
            width: 509rem * 0.0625;
            margin-left: 20rem * 0.0625;
                    @media (max-width: 780px) {
                width: 283px;}
        }
        .img1{
            width: 539rem * 0.0625;
            @media (max-width: 1130px) {
                display: none;
            }
        }
                @media (max-width: 780px) {
            flex-direction: column;
                margin-top: 26px;
                min-width: 100vw;
                margin-left: -12px;
        }
    }
    &-content{
        counter-reset: num;
        margin-top: 64px;
        border-top: 1px solid #041a2d;
                       @media (max-width: 720px) {
                       margin-top: 31px;
                }
    }
    &-block{
        counter-increment: num;
 
        &-head{
            &::before{
                content: '/0'counter(num);
    
                font-style: italic;
                font-weight: 300;
                font-size: 46px;
                line-height: 120%;
                letter-spacing: -0.02em;
                color: #6d7888;
                width: 120px;
                text-shadow: none;
                @media (max-width: 720px) {
                    font-size: 20px;
line-height: 120%;
width: 43px;
                }
            }
            cursor: pointer;
            font-weight: 400;
            font-size: 32px;
            line-height: 120%;
            letter-spacing: -0.02em;
            color:  #041a2d;
            padding: 24px 0px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #041a2d;
                     @media (max-width: 720px) {
                    font-size: 16px;
                    line-height: 120%;
    padding: 7.5px 0;
                }
            &::after{
                content: '';
                width: 52px;    
                height: 52px;
                background-size: contain;
                margin-left: auto;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='53' viewBox='0 0 52 53' fill='none'%3E%3Cpath d='M13 13.5L39 39.5M39 39.5H21.6667M39 39.5V22.1667' stroke='%23041A2D' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                               @media (max-width: 720px) {
                   height: 32px;
                   width: 32px;
                }
            }
            &:hover::after{
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='53' viewBox='0 0 52 53' fill='none'%3E%3Cpath d='M13 13.5L39 39.5M39 39.5H21.6667M39 39.5V22.1667' stroke='%23E3442E' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            }
            &:hover{
                font-weight: 600;
            }
            &.active{
                font-weight: 600;
                &::after{
                    transform: rotate(45deg);
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='53' viewBox='0 0 52 53' fill='none'%3E%3Cpath d='M13 13.5L39 39.5M39 39.5H21.6667M39 39.5V22.1667' stroke='%23E3442E' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                }
            }
        }
        &-sub{
            display: none;
            &-item{
                border-bottom: 1px solid rgba(4, 26, 45, 0.3);
                &:last-child{
                    border-color: #041a2d;
                }
                position: relative;
                &.active{
                    &::before{
                        content: '';
                        height: 100%;
                        width: 100vw;
                        left: 50%;
                        top: 0;
                        transform: translateX(-50%);
                        background-color: #f4faff;
                        position: absolute;
                    }
                }
            }
            &-head{
                font-weight: 400;
                font-size: 26px;
                line-height: 120%;
                letter-spacing: -0.02em;
                color: #6d7888;
                width: 100%;
                justify-content: space-between;
                padding: 20px 0 20px 120px;
                cursor: pointer;
                display: flex;
                align-items: center;
                position: relative;
                &::after{
                    background-image: url("data:image/svg+xml,%3Csvg width='42' height='43' viewBox='0 0 42 43' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.75 32L26.25 21.5L15.75 11' stroke='%236D7888' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
                    content: '';
                    height: 42px;
                    width: 42px;
                    flex-shrink: 0;
                    margin-left: auto;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                             @media (max-width: 720px) {
                             margin-left: 24px;
                             width: 24px;}
                }
                &:hover{
                    color: #041a2d;
                    font-weight: 500;
                    &::after{
                        background-image: url("data:image/svg+xml,%3Csvg width='42' height='43' viewBox='0 0 42 43' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.75 32L26.25 21.5L15.75 11' stroke='%23041A2D' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
                    }
                }
                &.active{
                    color: #041a2d;
                    font-weight: 500;
                    &::after{
                        transform: rotate(90deg);
                        background-image: url("data:image/svg+xml,%3Csvg width='42' height='43' viewBox='0 0 42 43' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.75 32L26.25 21.5L15.75 11' stroke='%23041A2D' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
                    }
                }
                         @media (max-width: 720px) {
                         font-size: 14px;
line-height: 120%;
padding-left: 43px;
padding-top: 4px;
padding-bottom: 4px;
                         
                         }
            }
            &-list{
                display: flex;
                flex-wrap: wrap;
                margin-top: 18px;
                gap: 44px 14px;
                padding-bottom: 100px;
                position: relative;
                         @media (max-width: 720px) {
                         margin-top: 6px;
                         gap: 22px 14px;
                         padding-bottom: 22px;
                         }
                &-wrap{
                    display: none;
                }
                &-item{
                    display: flex;
                    flex-direction: column;
                    width: calc(33.3333% - 9.33333333333px);
                    @media (max-width: 1150px) {
                        width: calc(50% - 14px);
                    }
                       @media (max-width: 720px) {
                        width: 100%;
                    }
                }
                &-img{
                    width: 100%;
                    height: auto;
                    aspect-ratio: 290 / 194;
                    object-fit: cover;
                }
                &-title{
         
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 120%;
                    letter-spacing: -0.02em;
                    color: #6d7888;
                    padding-right: 120px;
                    margin-top: 20px;
                    display: flex;
                    position: relative;
                    &::before{
                        content: '';
                        
                        background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 10.5L31.5 31.5M31.5 31.5H17.5M31.5 31.5V17.5' stroke='%23E3442E' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
                        height: 42px;   width: 42px;
                        position: absolute;
                        right: 0;
                        top: -2px;
                        opacity: 0;
                                 @media (max-width: 720px) {
                                 height: 32px;
                                 height: 32px;}
                    }
                    @media (max-width: 720px) {
                        font-size: 14px;
line-height: 120%;
    padding-right: 46px;
    margin-top: 9px;
                    }
                }
                &-item:hover &-title{
                    color: #041a2d;
                    &::before{
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.catalog{
    overflow: hidden;
}


.a-left, .a-right{
    width: 21px;    height: 55px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='55' viewBox='0 0 21 55' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 27.5L0.749996 54.7798L0.749998 0.220199L21 27.5Z' fill='white' /%3E%3C/svg%3E");
    @media (max-width: 780px) {
        background-image: url("data:image/svg+xml,%3Csvg width='55' height='14' viewBox='0 0 55 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27.5 0L54.7798 20.25L0.2202 20.25L27.5 0Z' fill='white' /%3E%3C/svg%3E");
        width: 55px;
        height: 14px;
    }
}
.a-left{
left: -5px;
            @media (max-width: 780px) {
        left: 50%;
        top: -1px;
        transform: translateX(-50%) scaleY(-1);
    }
}
.a-right{
right: -5px;
transform: scaleX(-1) translateY(-50%);
 @media (max-width: 780px) {
        left: 50%;
        bottom: -1px;
        top: unset;
        z-index: 3;
        transform: translateX(-50%) ;
    }
}