.breadcumbs{
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    color: #041a2d;
    display: flex;
    margin-top: 40px;
    margin-top: 70px;
    &-item{
        display: flex;
        align-items: center;
        &:not(:last-child){
            &::after{
                content: '';
                width: 24px;
                height: 24px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M9 18L15 12L9 6' stroke='%230D3854' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                background-size: contain;
                background-repeat: no-repeat;
                margin: 0 8px;
                @media (max-width: 899px) {
                    height: 17px;
                    width: 17px;
                }
            }
        }
        flex-shrink: 0;
        &:last-child{
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-decoration: none;   
            flex-shrink: 1;
        }
    }
    a {
        transition: all .3s;
        &:hover{
            color: #e3442e;
        }
    }
    span{
        color: #6d7888;
    }
    @media (max-width: 899px) {
        margin-top: 20px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;

    }
}