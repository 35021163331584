.about{
    display: flex;
    justify-content: space-between;
    margin-top: 70rem * 0.0625;
    @media (max-width: 899px) {
        flex-direction: column;
        margin-top: 3.375rem;
    }
    &-title{

    }
    &-desc{
        width: 54%;
        @media (max-width: 899px) {
            width: 100%;
        }
    }
    &-text{


        font-size: 22rem * 0.0625;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #6d7888;
        strong{
            font-weight: 500;
            color: #041a2d;
        }
        margin-top: 60rem * 0.0625;
        p:not(:first-child){
            margin-top: 1em;
        }
        @media (max-width: 899px) {
            margin-top: 0.75rem;
            font-size: 16px;
            line-height: 130%;
        }
    }
    &-img{
        width: 41%;
        position: relative;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
        }
        @media (max-width: 899px) {
            width: 100%;
            height: 25rem;
            margin-top: 3rem;
        }
        @media (max-width: 599px) {
            height: 16rem;
            margin-top: 2rem;
        }
    }
    &--inf{
        margin-top: 94rem * 0.0625;
        padding: 60rem * 0.0625 0;
        font-size: 60rem * 0.0625;
        line-height: 130%;
        letter-spacing: -0.02em;
        border-top: 1rem * 0.0625 solid #041a2d;
        border-bottom: 1rem * 0.0625 solid #041a2d;
        color: #041a2d;
        strong{
            font-weight: 600;
        }
        .accent{
            position: relative;
            background-color: #d7e7f1;
            padding-left: 0.2em;
            padding-right: 0.2em;
            margin-left: -0.2em;
            margin-right: -0.2em;
        }
        .next{
            display: inline-block;
            height: 52rem * 0.0625;width: 52rem * 0.0625;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='52' viewBox='0 0 52 52' fill='none'%3E%3Cpath d='M8.66602 26L43.3327 26M43.3327 26L30.3327 13M43.3327 26L30.3327 39' stroke='%23E3442E' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-size: contain;
            background-repeat: no-repeat;
            position: relative;
            top: 0.15em;
            @media (max-width: 899px) {
                display: none;
            }
        }
        @media (max-width: 899px) {
            font-size: 24px;
line-height: 130%;
max-width: calc(100% - 24px);
        }
    }
    &-story{
        margin-top: 120rem * 0.0625;
        padding-bottom: 64rem * 0.0625;
        @media (max-width: 825px) {
            margin-top: 6.5rem;
            padding-bottom: 3rem;
        }
        &-content{
            margin-top: 80rem * 0.0625;
            display: flex;
            @media (max-width: 825px) {
                flex-direction: column;
                margin-top: 2rem;
            }
        }
        &-inf{
            margin-left: 24rem * 0.0625;
            display: none;
            @media (max-width: 825px) {
                margin-left: 0;
                margin-top: 2.5rem;
            }
        }
        &-dt{
            font-style: italic;
            font-weight: 300;
            font-size: 18rem * 0.0625;
            line-height: 120%;
            letter-spacing: -0.02em;
            color: #e3442e;
            @media (max-width: 825px) {
                font-size: 18px;
line-height: 120%;
            }
        }
        &-text{
            font-weight: 400;
            font-size: 20rem * 0.0625;
            line-height: 130%;
            letter-spacing: -0.02em;
            color: #6d7888;
            max-width: 710rem * 0.0625;
            margin-top: 16rem * 0.0625;
            margin-bottom: 16rem * 0.0625;
            @media (max-width: 825px) {
                font-size: 14px;
                line-height: 130%;
            }
        }
        &-img{
            max-width: 100%;
        }
        &-dates{
            height: 610rem * 0.0625;
            padding-right: 30rem * 0.0625;
            overflow: scroll;
            margin: -2rem 0;
            width: max-content;
            @media (max-width: 825px) {
                height: auto;
                width: 100%;
                display: flex;
                margin: -2rem -1rem;
            }
            &::-webkit-scrollbar {
                width: 0;
                height: 0;
                background-color: #fff }
              &::-webkit-scrollbar-thumb {
                background-color: #e3442e;
                width: 0;
                height: 0; }
                
                &-wrap{position: relative;
                    &::before{
                        width: 100%;
                        height: 360rem * 0.0625;
                        background: linear-gradient(180deg, #fff 2.62%, rgba(255, 255, 255, 0) 100%);
                        
                        content: '';
                        position: absolute;
                        bottom: -3rem;
                        left: 50%;
                        transform: translateX(-50%) rotate(180deg);
                        pointer-events: none;
                        @media (max-width: 825px) {
                            left: -2rem;
                            height: 100%;
                            top: 0;
                            transform: none;
                            width: 67px;
                            background: linear-gradient(to right, #fff 2.62%, rgba(255, 255, 255, 0) 100%);
                        }
                    }
                    &::after{
                        width: 100%;
                        height: 360rem * 0.0625;
                        background: linear-gradient(180deg, #fff 2.62%, rgba(255, 255, 255, 0) 100%);
                        
                        content: '';
                        position: absolute;
                    top: -3rem;
                        left: 50%;
                        transform: translateX(-50%) ;
                        pointer-events: none;
                        @media (max-width: 825px) {
                            left: unset;
                            right: 0;
                            height: 100%;
                            top: 0;
                            transform: none;
                            width: 67px;
                            background: linear-gradient(to left, #fff 2.62%, rgba(255, 255, 255, 0) 100%);
                        }
                    }
                    &::before, &::after{
                        opacity: 0;
                    }
                    @media (min-width: 826px) {
                        &.--tscrolled{
                            &::after{
                                opacity: 1;
                            }
                        }
                        &.--bscrolled{
                            &::before{
                                opacity: 1;
                            }
                        }
                    }
         
                    @media screen {
                        &.--rscrolled{
                            &::after{
                                opacity: 1;
                            }
                        }
                        &.--lscrolled{
                            &::before{
                                opacity: 1;
                            }
                        }
                    }
                }
        }
        &-date{
            margin: 2rem 0;
            font-style: italic;
            font-weight: 300;
            font-size: 56rem * 0.0625;
            line-height: 120%;
            letter-spacing: -0.02em;
            color: #6d7888;
            cursor: pointer;
            @media (max-width: 825px) {
                flex-shrink: 0;
                margin-right: 1rem;
                margin-left: 1rem;
                white-space: nowrap;
                font-weight: 300;
font-size: 28px;
line-height: 120%;
letter-spacing: -0.02em;
            }
        }
        &-date.active{
            color: #e3442e;
        }
    }
    &-license{
        overflow: hidden;
        margin-top: 126rem * 0.0625;
        padding-top: 117rem * 0.0625;
        background-color: #f4faff;
        margin-bottom: 187rem * 0.0625;
        padding-bottom: 120rem * 0.0625;
        @media (max-width: 899px) {
            overflow: hidden;
            margin-top: 6.5rem;
            padding-top: 3.3125rem;
            background-color: #f4faff;
            margin-bottom: 6.6875rem;
            padding-bottom: 5.5rem;
        }
        &-slider{
         
            margin: 0 -1.5rem;
            margin-top: 42rem * 0.0625;
        }
        &-item{
            margin-right: 1.5rem;
            margin-left: 1.5rem;
            img{
                height: 330rem * 0.0625;
                width: auto;
            }
        }
    }
}


.news{
    margin-top: 70rem * 0.0625;
    padding-bottom: 70rem * 0.0625;
    @media (max-width: 899px) {
        margin-top: 3.375rem;
    }
}

.news-content{
    margin-top: 40rem * 0.0625;
    gap: 120rem * 0.0625 190rem * 0.0625;
    padding-bottom: 120rem * 0.0625;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 1200rem) {
        gap: 4.375rem 0;
        justify-content: space-between;
        margin-top: 34rem * 0.0625;
        padding-bottom: 6.5rem;
    }
}

.main-news__full:nth-of-type(even){
    flex-direction: row-reverse;
    .main-news__item-img{
        margin-left: 0;
        margin-right: 4rem;
    }
}

.news-more{
    border: 1rem * 0.0625 solid #0d3854;
border-radius: 4rem * 0.0625;
padding: 16rem * 0.0625 32rem * 0.0625;
width: 100%;
font-weight: 600;
font-size: 18rem * 0.0625;
line-height: 120%;
color: #0d3854;
text-align: center;
transition: all .3s;
&:hover{
    color: #e3442e;
    border-color: #e3442e;
}
@media (max-width: 899px) {
    font-size: 16px;
    padding: 16px 32px;
}
}

.pagination{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 60px;
    gap: 8px;
    @media (max-width: 899px) {
        margin-top: 30px;
    }
    &-item{
        @include flex-center();
        width: 54px;
        height: 54px;
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        text-align: center;
        color: #0d3854;
        &.active{
            background-color: #0d3854;
            color: #fff;
            border-radius: 4px;
        }
        
    }
}

.article{
    margin-top: 60rem * 0.0625;
    display: flex;
    justify-content: space-between;
    padding-bottom: 180rem * 0.0625;
    align-items: flex-start;
    @media (max-width: 899px) {
        flex-direction: column;
        margin-top: 2.75rem;
        padding-bottom: 9rem;
    }
    &-content{
        max-width: 100%;
    }
    &-date{
        font-family: var(--font-family);
font-weight: 400;
font-size: 20rem * 0.0625;
line-height: 140%;
letter-spacing: -0.02em;
color: #6d7888;
margin-bottom: 1.5rem;
@media (max-width: 899px) {
    font-size: 14px;
}
    }
    &-main-img{
        width: 100%;
        height: auto;
        margin-top: 40rem * 0.0625;
        margin-bottom: 32rem * 0.0625;
        @media (max-width: 599px) {
            height: 200px;
        }
    }

    &-text{
        font-size: 20rem * 0.0625;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #041a2d;
        @media (max-width: 599px) {
            font-size: 14px;
line-height: 130%;
        }
        h2{
            font-weight: 700;
            font-size: 20rem * 0.0625;
            // &:not(:first-child){
            //     margin-top: 3.6em;
            // }
        }
        ul{
            &:not(:first-child){
                margin-top: 1em;
            }
            li{
                padding-left: 1.5em;
                &:not(:first-child){
                    margin-top: .75em;
                }
                position: relative;
                &::before{
                    content: "";
                    position: absolute;
                    left: 0.6em;
                    top: 0.4em;
                    height: 6rem * 0.0625;
                    width: 6rem * 0.0625;
                    border-radius: 50%;
                    background-color: #041a2d;
                }
            }
        }
        p{
            &:not(:first-child){
                margin-top: .75em;
            }
        }
    }
    &-images{
        display: flex;
        gap: 16rem * 0.0625;
        margin-top: 40rem * 0.0625;
        @media (max-width: 599px) {
            overflow: scroll;
            width: 100%;
        }
        &-item{
            height: 316rem * 0.0625;
            flex-grow: 1;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
            @media (max-width: 599px) {
                height: 160px;
                width: 260px;
                flex-shrink: 0;
            }
        }
    }
    &-btn{
        margin-top: 120rem * 0.0625;
        width: max-content;
        max-width: 100%;
        text-align: center;
        @media (max-width: 599px) {
            margin-top: 6.6rem;
        }
    }
}

.sidebar{
    flex-shrink: 0;
    border-radius: 10rem * 0.0625;
padding: 24rem * 0.0625;
width: 393rem * 0.0625;
background-color: #f4faff;
margin-left: 80rem * 0.0625;
@media (max-width: 899px) {
    width: 100%;
    margin-left: 0;
    overflow: auto;
    margin-top: 87px;
    &-content{
        display: flex;
        gap: 30px;
    }
}
&-item{       
    color: #6d7888;
    margin-top: 38rem * 0.0625;
    display: block;
    @media (max-width: 899px) {
        width: 250px;
        flex-shrink: 0;
        @media (max-width: 899px) {
            margin-top: 20px;
        }
    }
    &-text{

        font-weight: 400;
        font-size: 20rem * 0.0625;
        line-height: 140%;
        letter-spacing: -0.02em;
 
    }
    &-date{

        font-weight: 400;
        font-size: 16rem * 0.0625;
        line-height: 140%;
        letter-spacing: -0.02em;
        margin-top: 20rem * 0.0625;
    }
}
&-title{
    font-family: var(--font-family);
font-weight: 500;
font-size: 32rem * 0.0625;
line-height: 130%;
letter-spacing: -0.02em;
color: #041a2d;
border-bottom: 1rem * 0.0625 solid #e3442e;
padding-bottom: 2rem;
margin-bottom: 50rem * 0.0625;
@media (max-width: 899px) {
    padding-bottom: 1rem;
    margin-bottom: 0.125rem;
    position: sticky;
    left: 0;
}
}
}


.docs{
    &-banner{
        margin-top: 40px;
        img{
            height: 441px;
            width: 100%;
            object-fit: cover;
        }
        position: relative;
        @media (max-width: 450px) {
            margin-top: 26px;
            width: 100%;
            margin-left: 0;margin-right: 0;
            padding: 0;
            img{
                height: 275px;
            }
        }
    }
}

.docs-content{
    margin-top: 6rem;
    padding-bottom: 7.5rem;
    @media (max-width: 720px) {
        margin-top: 3rem;
        padding-bottom: 6rem;
    }
}

.docs-list{
    counter-reset: num;
    border-bottom: 1px solid #041a2d;
    margin-top: 40px;
    @media (max-width: 720px) {
       margin-top: 32px;
    }
}

.docs-item{
    font-weight: 400;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #041a2d;
    display: flex;
    counter-increment: num;
    align-items: center;
    border-top: 1px solid #041a2d;
    padding-top: 23px;
    padding-bottom: 23px;
    &::before{
        content: '/0'counter(num);
        font-style: italic;
        font-weight: 300;
        font-size: 46px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #6d7888;
        width: 120px;
        flex-shrink: 0;
    }
    &::after{
        content: '';
        width: 52px;    
        height: 52px;
        background-size: contain;
        margin-left: auto;
        flex-shrink: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='52' height='53' viewBox='0 0 52 53' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.66602 26.5H43.3327M43.3327 26.5L30.3327 13.5M43.3327 26.5L30.3327 39.5' stroke='%23041A2D' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
                       @media (max-width: 720px) {
           height: 32px;
           width: 32px;
        }
    }
    &:hover::after{
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='53' viewBox='0 0 52 53' fill='none'%3E%3Cpath d='M8.66602 26.5H43.3327M43.3327 26.5L30.3327 13.5M43.3327 26.5L30.3327 39.5' stroke='%23E3442E' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
    span{
        display: flex;
        align-items: center;
        &::before{
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='37' viewBox='0 0 36 37' fill='none'%3E%3Cpath d='M30 11V10.7C30 8.17976 30 6.91965 29.5095 5.95704C29.0781 5.11031 28.3897 4.4219 27.543 3.99047C26.5804 3.5 25.3202 3.5 22.8 3.5H13.2C10.6798 3.5 9.41965 3.5 8.45704 3.99047C7.61031 4.4219 6.9219 5.11031 6.49047 5.95704C6 6.91965 6 8.17976 6 10.7V26.3C6 28.8202 6 30.0804 6.49047 31.043C6.9219 31.8897 7.61031 32.5781 8.45704 33.0095C9.41965 33.5 10.6798 33.5 13.2 33.5H18.75M18.75 17H12M17.25 23H12M24 11H12M27 27.5V19.25C27 18.0074 28.0074 17 29.25 17C30.4926 17 31.5 18.0074 31.5 19.25V27.5C31.5 29.9853 29.4853 32 27 32C24.5147 32 22.5 29.9853 22.5 27.5V21.5' stroke='%236D7888' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            content: '';
            height: 36px;
            width: 36px;
            flex-shrink: 0;
            background-size: contain;
            margin-right: 12px;
        }

        margin-right: 20px;
    }
    @media (max-width: 720px) {
        font-size: 14px;
        line-height: 120%;
        &::before{
            font-size: 20px;
            line-height: 120%;
            width: 44px;

        }
        span{
            &::before{
                height: 18px;
                width: 18px;
                margin-right: 8px;
            }
        }
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

.doc-content{
    margin-top: 70px;
    @media (max-width: 599px) {
        margin-top: 43px;
    }
}

.page-article{
    font-weight: 400;
    font-size: 20rem * 0.0625;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #041a2d;
    margin-top: 70px;
    padding-bottom: 90px;
    @media (max-width: 699px) {
        font-size: 14px;
        line-height: 120%;
        margin-top: 25px;
    }
    h2{
        font-weight: 700;
        font-size: 28px;
        line-height: 120%;
        margin-bottom: 1.9em;
        // &:not(:first-child){
        //     margin-top: 3.6em;
        // }
        @media (max-width: 699px) {
            font-size: 18px;
line-height: 110%;
margin-bottom: 0;
        }
    }
    h3{
        font-size: 20rem * 0.0625;
        margin-top: 3rem;
        @media (max-width: 699px) {
            margin-top: 2.5rem;
            font-size: 14px;
        }
    }

    p:not(:last-child){
        margin-bottom: 0.75em;
    }
    p{
        line-height: 130%;
    }
    ol li{
        list-style: numeric;
    }
    ol {
        padding-left: 1.5em;
        margin-top: 1.6rem;
        margin-bottom: 1.6rem;
        line-height: 130%;
        li{
            margin-top: .5em;
        }
    }
    ul{
        &:not(:first-child){
            margin-top: 1em;
        }
        li{
            padding-left: 1.5em;
            &:not(:first-child){
                margin-top: .55em;
            }
            position: relative;
            &::before{
                content: "";
                position: absolute;
                left: 0.6em;
                top: 0.4em;
                height: 6rem * 0.0625;
                width: 6rem * 0.0625;
                border-radius: 50%;
                background-color: #041a2d;
            }
        }
    }
}