.sec-gap{
    margin-top: 122px;
    @media (max-width: $desktop_md_res) {
        margin-top: 96px;
    }
    @media (max-width: $tablet_res) {
        margin-top: 72px;
        .banner-main + &{
            margin-top: 96px;
        }
    }
    @media (max-width: $mobile_res) {
        margin-top: 48px;
        .banner-main + &{
            margin-top: 36px;
        }
    }
}

.sec-gap--min{
    margin-top: 48px;
    @media (max-width: $desktop_md_res) {
        margin-top: 22px;
    }
    @media (max-width: $tablet_res) {
        margin-top: 36px;
    }
    @media (max-width: 620px) {
        margin-top: 16px;
    }
}

.sec-gap--sm{
    margin-top: 24px;
    @media (max-width: $desktop_md_res) {
        margin-top: 22px;
    }
    @media (max-width: $tablet_res) {
        margin-top: 36px;
    }
    @media (max-width: 620px) {
        margin-top: 16px;
    }
}

.page-content{
    padding-top: 120px;
    margin-top: var(--info-banner-h);
    @media (max-width: $desktop_md_res) {
        padding-top: 90px;
    }
    @media (max-width: $tablet_res) {
        padding-top: 102px;
    }
    @media (max-width: 620px) {
        padding-top: 111px;
        margin-top: 0;
    }
}

.page-wrapper{
    position: relative;
}

.sec-gap--tb-min{
    @media (max-width: $tablet_res) {
        margin-top: 16px;
    }
}

.shop-list-arrows{
    position: absolute;
    display: flex;
    right: 0;
    bottom: 0;
    gap: 8px;
    @media (max-width: $desktop_md_res) {
        gap: 4px;
    }
    @media (max-width: 620px) {
        display: none;
    }
}