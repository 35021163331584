.vacancy{
    margin-top: 70px;
    @media (max-width: 699px) {
        margin-top: 44px;
    }
    &-content{
        margin-top: 60px;
        padding-bottom: 160px;
        @media (max-width: 699px) {
            margin-top: 30px;
            padding-bottom: 80px;
        }
    }
    &-item{
        border-radius: 20px;
        padding: 32px 24px;
        box-shadow: 0 2px 8px 0 rgba(13, 56, 84, 0.1);
        background: #fff;
        &:not(:last-child){
            margin-bottom: 40px;
            @media (max-width: 699px) {
                margin-bottom: 17px;
            }
        }
        @media (max-width: 699px) {
            padding: 24px 16px;
            border-radius: 5px;
        }
        &-head{
            display: flex;
            justify-content: space-between;

            font-weight: 600;
            font-size: 26px;
            line-height: 120%;
            color: #041a2d;
            @media (max-width: 699px) {
                font-size: 16px;
line-height: 120%;
            }
        }
        &-s{
            font-size: 22px;
            @media (max-width: 699px) {
                font-size: 16px;
                text-align: right;
            }
        }
        &-city{

            font-size: 18px;
            line-height: 120%;
            color: #6d7888;
            margin-top: 18px;
            @media (max-width: 699px) {
                font-size: 14px;
            }
        }
        &-text{
            max-width: 995px;
            font-weight: 400;
            font-size: 16px;
            line-height: 120%;
            letter-spacing: -0.02em;
            color: #041a2d;
       
            &:not(.active){
                
                overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            text-decoration: none;
            @media (max-width: 699px) {
                -webkit-line-clamp: 7;
            }
            }

            p{
                margin-top: 0.5em;
                @media (max-width: 699px) {
                   margin-top: 0.7em;
                }
            }
            @media (max-width: 699px) {
                font-size: 14px;
            }
        }
        
    }
    &-btn-more{
        &::after{
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M7 6L12 11L17 6M7 13L12 18L17 13' stroke='%236D7888' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            height: 24px;
            width: 24px;
            content: '';
            margin-left: 8px;
            flex-shrink: 0;
            background-size: contain;
        }
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        text-transform: uppercase;
        color: #6d7888;
        display: flex;
        align-items: center;
        margin-top: 65px;
        &.active::after{
            transform: rotate(180deg);
        }
        .--a{
            display: none;
        }
        &.active{
            .--na{
                display: none;
            }
            .--a{
                display: inline;
            }
        }
        @media (max-width: 699px) {
           margin-top: 48px;
        }
    }
    &-btn{
        margin-top: 25px;
        @media (max-width: 699px) {
            margin-top: 16px;
            width: 100%;
        }
    }
}