.product{
    margin-top: 70rem * 0.0625;
    padding-bottom: 300rem * 0.0625;
    overflow: hidden;
    @media (max-width: 699px) {
        margin-top: 3.5rem;
        padding-bottom: 6.75rem;
    }
    &-head{
        &-text{
            width: 42.2%;
            @media (max-width: 899px) {
                width: 49%;
            }
            @media (max-width: 699px) {
                width: 100%;
            }
        }
        display: flex;
        justify-content: space-between;
        @media (max-width: 699px) {
            flex-direction: column;
        }
    }
    &-title{
        font-weight: 700;
        font-size: 42rem * 0.0625;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #041a2d;
        @media (max-width: 699px) {
            font-size: 24px;
line-height: 120%;
        }
    }
    &-desc{
        font-weight: 400;
        font-size: 22rem * 0.0625;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #6d7888;
        margin-top: 32rem * 0.0625;
        @media (max-width: 699px) {
            margin-top: 1rem;
            font-size: 16px;
            line-height: 130%;
        }
    }
    &-slider{
        width: 40.8%;
        margin-right: 7.3rem;
        position: relative;
        &-img{
            display: block;
            height: 350rem * 0.0625;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
            @media (max-width: 699px) {
                height: auto;
                aspect-ratio: 260 / 160;
                width: 76vw;
                margin-right: 10px;
                margin-right: 10px;
            }
        }
        @media (max-width: 899px) {
            margin-right: 1rem;
        }
        @media (max-width: 699px) {
            width: 100%;
            margin-right: 0;
            margin-top: 25px;
            .slick-list{
                overflow: visible;
            }
        }
    }
    &-info{
        margin-top: 102rem * 0.0625;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @media (max-width: 699px) {
            margin-top: 2.2rem;
        }
        &-item{
            width: 45.1%;
            &:nth-child(even){
                width: 49%;
            }
            margin-bottom: 3.6em;
            @media (max-width: 899px) {
                &:nth-child(n){
                    width: 100%;
                }
                margin-bottom: 1.5em;
            }
        }
        
    font-weight: 400;
    font-size: 18rem * 0.0625;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #041a2d;
    @media (max-width: 699px) {
        font-size: 14px;
        line-height: 120%;
    }
    h2{
        font-weight: 700;
        font-size: 20rem * 0.0625;
        margin-bottom: 1em;
        // &:not(:first-child){
        //     margin-top: 3.6em;
        // }
        @media (max-width: 699px) {
            font-size: 16px;
line-height: 110%;
        }
    }

    &--add{
        margin-top: 17rem * 0.0625;
        max-width: 842rem * 0.0625;
        h2{
            margin-bottom: 0.85em;

        }
    }

    p:not(:last-child){
        margin-bottom: 0.75em;
    }
    p{
        line-height: 120%;
    }
    ol li{
        list-style: numeric;
    }
    ol {
        padding-left: 1.5em;
        li{
            margin-top: .75em;
        }
    }
    ul{
        &:not(:first-child){
            margin-top: 1em;
        }
        li{
            padding-left: 1.5em;
            &:not(:first-child){
                margin-top: .55em;
            }
            position: relative;
            &::before{
                content: "";
                position: absolute;
                left: 0.6em;
                top: 0.4em;
                height: 6rem * 0.0625;
                width: 6rem * 0.0625;
                border-radius: 50%;
                background-color: #041a2d;
            }
        }
    }
    }
    &-info + &-info{
        margin-top: 20rem * 0.0625;
    }
    &-info--color{
        padding: 32rem * 0.0625 24rem * 0.0625;
        background-color: #f4faff;
        @media (max-width: 699px) {
            padding: 16px 12px;
        }
    }
    &-btn{
        width: max-content;
        margin-top: 118rem * 0.0625;
        @media (max-width: 599px) {
            margin-top: 3.5rem;
            width: 100%;
        }
    }
}

.arrow{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='42' height='42' viewBox='0 0 42 42' fill='none'%3E%3Cpath d='M15.75 31.5L26.25 21L15.75 10.5' stroke='%23041A2D' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    height: 42rem * 0.0625;
    width: 42rem * 0.0625;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &.slick-disabled{
        opacity: .3;
    }
    @media (max-width: 699px) {
        display: none;
    }
}

.arrow-next{
    right: -4rem;
}
.arrow-prev{
    left: -4rem;
    transform: translateY(-50%) scaleX(-1);
}

.product-table{
    margin-top: 34rem * 0.0625;
    font-weight: 400;
    font-size: 20rem * 0.0625;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #041a2d;
        @media (max-width: 699px) {
            font-size: 12px;
            margin-top: 1rem;
        }
        h2{
            font-weight: 700;
            font-size: 20rem * 0.0625;
            // &:not(:first-child){
            //     margin-top: 3.6em;
            // }
            @media (max-width: 699px) {
                font-size: 12px;
            }
        }
        ul{
            &:not(:first-child){
                margin-top: 1em;
            }
            li{
                padding-left: 1.5em;
                &:not(:first-child){
                    margin-top: .75em;
                }
                @media (max-width: 699px) {
                    &:not(:first-child){
                        margin-top: .25em;
                    }
                }
                position: relative;
                &::before{
                    content: "";
                    position: absolute;
                    left: 0.6em;
                    top: 0.4em;
                    height: 6rem * 0.0625;
                    width: 6rem * 0.0625;
                    border-radius: 50%;
                    background-color: #041a2d;
                }
            }
        }
        width: 100%;
        td{
            width: 50%;
            border-radius: 2rem * 0.0625;
            padding: 9rem * 0.0625 16rem * 0.0625;
            border-width: 2rem * 0.0625;
            @media (max-width: 699px) {
                padding: 6px 8px;
            }
        }
        tr:nth-child(odd) td{
            background: rgba(109, 120, 136, 0.1);
        }
        tr:nth-child(even) td{
            background: rgba(109, 120, 136, 0.2);
        }
}