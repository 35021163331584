.btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px 16px 32px;
    border-radius: 4px;
    background: rgb(13, 56, 84);
    color: rgb(255, 255, 255);
font-family: Inter;
font-size: 18px;
font-weight: 600;
line-height: 120%;
letter-spacing: 0%;
&:not(.btn--back){
    &::after{
        width: 24px;
    height: 24px;
    margin-left: 4px;
    flex-shrink: 0;
    content: '';
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg width='24.000000' height='24.000000' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdesc%3E Created with Pixso. %3C/desc%3E%3Cdefs%3E%3CclipPath id='clip2_3097'%3E%3Crect id='chevron-right' width='24.000000' height='24.000000' fill='white' fill-opacity='0'/%3E%3C/clipPath%3E%3C/defs%3E%3Crect id='chevron-right' width='24.000000' height='24.000000' fill='%23FFFFFF' fill-opacity='0'/%3E%3Cg clip-path='url(%23clip2_3097)'%3E%3Cpath id='Icon' d='M9 18L15 12L9 6' stroke='%23FFFFFF' stroke-opacity='1.000000' stroke-width='2.000000' stroke-linejoin='round' stroke-linecap='round'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    }
}
&--back{
    &::before{
        width: 24px;
        height: 24px;
        margin-right: 4px;
        flex-shrink: 0;
        content: '';
        background-size: contain;
        background-image: url("data:image/svg+xml,%3Csvg width='24.000000' height='24.000000' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdesc%3E Created with Pixso. %3C/desc%3E%3Cdefs%3E%3CclipPath id='clip2_3097'%3E%3Crect id='chevron-right' width='24.000000' height='24.000000' fill='white' fill-opacity='0'/%3E%3C/clipPath%3E%3C/defs%3E%3Crect id='chevron-right' width='24.000000' height='24.000000' fill='%23FFFFFF' fill-opacity='0'/%3E%3Cg clip-path='url(%23clip2_3097)'%3E%3Cpath id='Icon' d='M9 18L15 12L9 6' stroke='%23FFFFFF' stroke-opacity='1.000000' stroke-width='2.000000' stroke-linejoin='round' stroke-linecap='round'/%3E%3C/g%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        transform: scaleX(-1);
    }
}
transition: all .3s;
&:hover{
    background-color: rgb(227, 68, 46);
}
@media (max-width: 600px) {
    font-size: 16px;
    padding: 12px 24px;
}
}