.popup{
    background-color: rgba(0, 0, 0, .69);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 9999;
    display: none;
    &-wrap{
        width: 100%;
        height: 100%;
        overflow: auto;
        // padding: 15rem * 0.0625;
        display: flex;

        &::-webkit-scrollbar {
            width: 0rem * 0.0625;
        }
    }
}



.close{
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M35 20C35 28.2843 28.2843 35 20 35C11.7157 35 5 28.2843 5 20C5 11.7157 11.7157 5 20 5C28.2843 5 35 11.7157 35 20ZM20 22.3571L14.5119 27.8452L12.1548 25.4882L17.643 20.0001L12.1548 14.5119L14.5118 12.1549L20 17.643L25.4882 12.1549L27.8452 14.5119L22.357 20.0001L27.8452 25.4882L25.4881 27.8452L20 22.3571Z' fill='white'/%3E%3C/svg%3E");
}


.form{
padding: 80px;
gap: 30px;
width: 720px;
background: #F4FAFF;
border-radius: 20px;
position: relative;
margin: auto;
&-title{
    text-align: center;
}
&-sub{
    text-align: center;
    font-size: 14px;
}
&-group{
    max-width: 460px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
}
&-input{


padding: 10px 15px;

width: 100%;
max-width: 460px;
height: 52px;

background: #FFFFFF;
border: 1px solid rgba(13, 56, 84, 0.2);
border-radius: 4px;
/* Фамилия */
display: block;
margin-left: auto;
margin-right: auto;

font-size: 14px;
line-height: 120%;
/* identical to box height, or 17px */
letter-spacing: -0.02em;

/* Black */
color: #041A2D;

&::placeholder{
    color: #041A2D;

    opacity: 0.6;
}
}
&-file{
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all .5s;
    &:hover{
        background-color: #e3442e;
    }
    &::after{
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 10L12 15M12 15L7 10M12 15V3' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        width: 24px;
        height: 24px;
        content: '';
  
    }
    justify-content: center;
gap: 10px;
width: 187px;
height: 52px;
background: #0D3854;
border: 1px solid rgba(13, 56, 84, 0.2);
border-radius: 4px;
font-size: 14px;
line-height: 120%;
/* identical to box height, or 17px */
letter-spacing: -0.02em;
color: #FFFFFF;
}
&-agree{


font-size: 14px;
line-height: 120%;
letter-spacing: -0.02em;

color: #041A2D77;

a{
    text-decoration: underline;
}
display: flex;
align-items: center;
gap: 10px;

}
&-box{
flex-shrink: 0;

width: 25px;
height: 25px;
background-color: #FFFFFF;
border: 1px solid rgba(13, 56, 84, 0.2);
border-radius: 2px;


background-size: 19px;
background-repeat: no-repeat;
background-position: center;

}
input:checked + &-box{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='14' viewBox='0 0 19 14' fill='none'%3E%3Cpath opacity='0.3' d='M17.5 1.5L6.5 12.5L1.5 7.5' stroke='%230D3854' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.close{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'%3E%3Cpath d='M8.00195 21.334L22.2877 8.00065' stroke='%23041A2D' stroke-width='3.33333' stroke-linecap='round'/%3E%3Cpath d='M22.2852 21.334L7.99944 8.00065' stroke='%23041A2D' stroke-width='3.33333' stroke-linecap='round'/%3E%3C/svg%3E");
    height: 40px;
    width: 40px;
    position: absolute;
    top: 20px;
    right: 20px;
}
max-width: calc(100% - 40px);
@media (max-width: 640px) {
    padding: 40px 20px;
}
}

