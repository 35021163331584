.title{
    color:  #041A2D;
font-family: Inter;
font-size: 54rem * 0.0625;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 64.8rem * 0.0625 */
letter-spacing: -1.08rem * 0.0625;
@media (max-width: 600px) {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: -0.02em;
    text-align: left;
    
    
}
&--num{
    &::before{
        color:  #E3442E;
font-family: Inter;
font-size: 46rem * 0.0625;
font-style: italic;
font-weight: 300;
line-height: 120%; /* 55.2rem * 0.0625 */
letter-spacing: -0.92rem * 0.0625;
content: '/0'counter(number);
display: block;
margin-bottom: 12rem * 0.0625;
@media (max-width: 600px) {
    font-family: Inter;
    font-size: 18px;
    font-style: italic;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 2px;
}
    }
}
}