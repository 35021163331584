.container{
  max-width: 1430rem * 0.0625;
  width: calc(100% - 64rem * 0.0625);
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  position: relative;
  z-index: 1;

  @media (max-width: $desktop_md_res) {
    max-width: calc(100% - 80px);
    width: 100%;
  }
  @media (max-width: $tablet_res) {
    max-width: calc(100% - 72px);
  }
  @media (max-width: $mobile_res) {
    max-width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }
}

.container-min{
  max-width: 1080px;
  width: calc(100% - 128px);
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  position: relative;
  z-index: 1;

  @media (max-width: $desktop_md_res) {
    max-width: 720px;
    width: 100%;
  }
  @media (max-width: $tablet_res) {
    max-width: calc(100vw - 72px);
  }
  @media (max-width: $mobile_res) {
    max-width: calc(100vw - 48px);
  }
}

:root{
  --info-banner-h: 0px;
}

html{
  font-size: 16px;
  @media (max-width: 1540px) {
    font-size: 14px;
  }
  @media (max-width: 1300px) {
    font-size: 12px;
  }
}
body{
  margin: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  font-family: 'Inter';
  &.fixed{
    overflow: hidden;
  }
  &.--lightgray{
    background-color: #f4faff;
  }
}

body::-webkit-scrollbar {
  width: 0.4rem;
  background-color: #fff }
body::-webkit-scrollbar-thumb {
  background-color: #e3442e;
  width: 0.4rem; }

li{
  list-style: none;
}
ul{
  margin: 0;
  padding: 0;
}
p{
  margin: 0;
}

a{
  text-decoration: none;
  outline: none;
  color: inherit;
}

img{
  user-select: none;
}

button, input{
  border: none;
  outline: none;
  background: none;
  padding: 0;
  font-family: inherit;
}

button{
  cursor: pointer;
}

*{
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6{
  margin: 0;
}

.text-center{
  text-align: center;
}

.text-left{
  text-align: left;
}

.fw-bold{
  font-weight: bold;
}

.fw-black{
  font-weight: 900;
}

.fw-normal{
  font-weight: normal;
}


.crossed{
  text-decoration: line-through;
}

.visually-hidden {
	position: absolute !important;
	clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
	clip: rect(1px, 1px, 1px, 1px);
	padding:0 !important;
	border:0 !important;
	height: 1px !important; 
	width: 1px !important; 
	overflow: hidden;
}

.decor{
  position: absolute;
}

picture{
  display: block;
  img{
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}


.slick-initialized .slick-slide {
  display: block;
  height: max-content;
}

.slick-track {
  height: max-content;
}

section{
  position: relative;
  z-index: 1;
  width: 100%;

}

header, footer{
  z-index: 2;
}


.m-hidden{
  @media (max-width: 899px){
    display: none;
  }
}

.pc-hidden{

  @media (min-width: $mobile_res + 1){
    display: none;
  }
}


input:not([type=checkbox]):not([type=radio]),
textarea, 
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.page-wrapper{
  width: 100%;

}


.preloader{
  background-color: #fff;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999999;
  @include flex-center();
  svg, img{
    width: 18rem;
    height: 18rem;
  }
}