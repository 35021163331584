@mixin cover() {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin star-filled(){
  background-color: #D9E5E0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.61784 0.995928C9.17217 -0.331977 11.0534 -0.331975 11.6078 0.995929L13.5451 5.63682C13.5697 5.69578 13.6238 5.73728 13.6871 5.74585L18.8211 6.44072C20.2255 6.6308 20.7261 8.40521 19.6281 9.30122L15.6753 12.5268C15.6221 12.5702 15.5982 12.64 15.6137 12.7069L16.8768 18.165C17.2117 19.6125 15.584 20.7098 14.3678 19.8562L10.2162 16.9424C10.1542 16.8988 10.0715 16.8988 10.0094 16.9424L5.85776 19.8562C4.64161 20.7098 3.01385 19.6126 3.3488 18.165L4.61178 12.7069C4.62725 12.64 4.60337 12.5702 4.55022 12.5268L0.597483 9.30121C-0.500501 8.40519 7.52449e-05 6.6308 1.40445 6.44072L6.53855 5.74585C6.60187 5.73728 6.6559 5.69578 6.68051 5.63682L8.61784 0.995928ZM10.2789 1.55066C10.2173 1.40311 10.0083 1.40311 9.9467 1.55066L8.00938 6.19155C7.78784 6.72224 7.30156 7.09571 6.73168 7.17284L1.59759 7.86771C1.44154 7.88883 1.38593 8.08599 1.50792 8.18554L5.46066 11.4112C5.93904 11.8016 6.15391 12.43 6.01471 13.0315L4.75174 18.4897C4.73987 18.5409 4.747 18.5745 4.75723 18.5986C4.76921 18.6268 4.79229 18.6564 4.82649 18.6795C4.8607 18.7026 4.89682 18.7128 4.92746 18.7134C4.95362 18.7138 4.98742 18.7078 5.03051 18.6776L9.18215 15.7637C9.74067 15.3717 10.4849 15.3717 11.0435 15.7637L15.1951 18.6775C15.2381 18.7078 15.2719 18.7138 15.2981 18.7133C15.3287 18.7128 15.3649 18.7025 15.3991 18.6795C15.4333 18.6564 15.4564 18.6268 15.4683 18.5986C15.4786 18.5745 15.4857 18.5409 15.4738 18.4896L14.2108 13.0315C14.0716 12.43 14.2864 11.8016 14.7648 11.4112L18.7177 8.18554C18.8397 8.08598 18.784 7.88883 18.628 7.86771L13.4939 7.17284C12.9241 7.09571 12.4378 6.72224 12.2162 6.19155L10.2789 1.55066Z' fill='%23427E66'/%3E%3C/svg%3E");
}